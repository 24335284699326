import PropTypes from 'prop-types';
// material
import { MenuItem, TextField } from '@mui/material';
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import tracker from "../api/tracker";

// ----------------------------------------------------------------------

SortDropdown.propTypes = {
    sort: PropTypes.string,
    setSort: PropTypes.func
};

export default function SortDropdown({ sort, setSort}) {
    const [t] = useTranslation();

    const sortOptions = [
        { value: 'desc', label: t('Latest') },
        { value: 'asc', label: t('Oldest') },
    ];

    const handleSort = (event) =>{
        setSort(event.target.value)
    }

    return (
        <TextField select size="small" value={sort} onChange={handleSort}>
            {sortOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
}
