import PropTypes from 'prop-types';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Avatar, Typography, CardContent } from '@mui/material';
// utils
import { fDate } from '../../../utils/formatTime';
import { fShortenNumber } from '../../../utils/formatNumber';
//
import SvgIconStyle from '../../../components/SvgIconStyle';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const TitleStyle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  cursor: "pointer",
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled,
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

EventCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function EventCard({ event, index }) {
  const createdAt = event.created_at;
  const { date,image,hashid,name, view, comment, share, author, date_range: dateRange } = event;
  const navigate = useNavigate();
  const latestEventLarge = false;
  const latestEvent = false;

  const EVENT_INFO = [
    // { number: comment, icon: 'eva:message-circle-fill' },
    // { number: view, icon: 'eva:eye-fill' },
    // { number: share, icon: 'eva:share-fill' },
  ];

  return (
      <Grid item xs={12} sm={latestEventLarge ? 12 : 6} md={latestEventLarge ? 6 : 3}>
        <Card sx={{ position: 'relative' }}>
          <CardMediaStyle
              sx={{
                ...((latestEventLarge || latestEvent) && {
                  pt: 'calc(100% * 4 / 3)',
                  '&:after': {
                    top: 0,
                    content: "''",
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                  },
                }),
                ...(latestEventLarge && {
                  pt: {
                    xs: 'calc(100% * 4 / 3)',
                    sm: 'calc(100% * 3 / 4.66)',
                  },
                }),
              }}
          >
            <SvgIconStyle
                color="paper"
                src="/static/icons/shape-avatar.svg"
                sx={{
                  width: 80,
                  height: 36,
                  zIndex: 9,
                  bottom: -15,
                  position: 'absolute',
                  color: 'background.paper',
                  ...((latestEventLarge || latestEvent) && { display: 'none' }),
                }}
            />
            <AvatarStyle
                alt={author}
                src={event.author_picture}
                onClick={() => {
                    navigate(`/dashboard/event/photos/${hashid}`)
                }}
                sx={{
                  ...((latestEventLarge || latestEvent) && {
                    zIndex: 9,
                    top: 24,
                    left: 24,
                    width: 40,
                    height: 40,

                  }),
                }}
            />

            <CoverImgStyle alt={name} src={image} />
          </CardMediaStyle>

          <CardContent
              sx={{
                pt: 4,
                ...((latestEventLarge || latestEvent) && {
                  bottom: 0,
                  width: '100%',
                  position: 'absolute',
                }),
              }}
          >
            <Typography gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
              {dateRange}
            </Typography>

            <TitleStyle
                to={`/dashboard/event/edit/${hashid}`}
                color="inherit"
                variant="subtitle2"
                underline="hover"
                component={RouterLink}
                sx={{
                  ...(latestEventLarge && { typography: 'h5', height: 60 }),
                  ...((latestEventLarge || latestEvent) && {
                    color: 'common.white',
                  }),
                }}
            >
              {name}
            </TitleStyle>

            <InfoStyle>
              {EVENT_INFO.map((info, index) => (
                  <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ml: index === 0 ? 0 : 1.5,
                        ...((latestEventLarge || latestEvent) && {
                          color: 'grey.500',
                        }),
                      }}
                  >
                    <Iconify icon={info.icon} sx={{ width: 16, height: 16, mr: 0.5 }} />

                  </Box>
              ))}
            </InfoStyle>
          </CardContent>
        </Card>
      </Grid>
  );
}
