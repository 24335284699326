import { Link as RouterLink } from 'react-router-dom';
import {Pagination} from "@mui/lab";
// material
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import {FadeLoader} from "react-spinners";
// components
import Page from '../components/Page';

import Iconify from '../components/Iconify';
import SortDropdown from "../components/SortDropdown";
import useQuery from "../hooks/useQuery";
import SearchModel from "../components/SearchModel";
import RetreatCard from '../sections/@dashboard/retreats/RetreatCard';

export default function Retreats() {
  const { t } = useTranslation();

  const [retreats ,setRetreats] = useState([]);
  const [pagination, setPagination] = useState({total: 0, per_page: 0, current_page: 1, last_page: 1});
  const [page, setPage] = useState(1);
  const [filterEvent ,setfilterEvent] = useState("");
  const [sort ,setSort] = useState('desc');
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useQuery();
  const handleChange = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setIsLoading(true)
      await fetchData('retreats', filterEvent, sort, setRetreats, 'date', setPagination, page)
      setIsLoading(false)
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [filterEvent, sort, page])

  return (
      <Page title={t('retreats')}>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {t('retreats')}
            </Typography>
            <Button variant="contained" component={RouterLink} to="/dashboard/retreat/create" startIcon={<Iconify icon="eva:plus-fill" />}>
              {t('create_retreat')}
            </Button>
          </Stack>

          <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
            <SearchModel filterName={filterEvent} onFilterName={setfilterEvent} placeHolder={t("search_retreats")} />
            <SortDropdown sort={sort} setSort={setSort} />
          </Stack>

          <Grid container spacing={3}>
            {isLoading ? (<FadeLoader
                color={'blue'}
                loading={isLoading}
                cssOverride={{
                  margin: '0 auto',
                }}
                size={50}
            />) : (
                retreats.map((retreat, index) => (
                  <RetreatCard key={retreat.id} retreat={retreat} index={index} />
              )
                )) }

          </Grid>
          <div style={{display: "flex", alignItems: 'center', justifyContent: 'center', marginTop: '20px'}}>
            <Pagination count={pagination.last_page} page={page}  variant="outlined" shape="rounded" onChange={handleChange} />
          </div>
        </Container>
      </Page>
  );
}
