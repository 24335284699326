import { FadeLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
// components
import Scrollbar from '../../../components/Scrollbar';
import Iconify from "../../../components/Iconify";
import SearchNotFound from '../../../components/SearchNotFound';
import useQuery from "../../../hooks/useQuery";
import { ListHead, ListToolbar, MoreMenu } from '../../../components/tables';
import { useApi } from "../../../hooks/useApi";
import { RHFTextField } from "../../../components/hook-form";


const override = {  
  margin: "0 auto",
};

export default function RetreatParticipants({retreat}) {
  const [participants ,setParticipants] = useState([]);
  const [editingParticipant ,setEditingParticipant] = useState();
  const [pagination, setPagination] = useState({total: 0, per_page: 0, current_page: 1, last_page: 1});
  const [page, setPage] = useState(1);
  const [filter ,setFilter] = useState("");
  const [sort ,setSort] = useState('asc');
  const [sortField ,setSortField] = useState('name');
  const [selected, setSelected] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editingStatus, setEditingStatus] = useState();
  const {doSave} = useApi();
  const [loading, setLoading] = useState(true);
  
  const fetchData = useQuery();
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'name', label: t('name'), alignRight: false },
    { id: 'email', label: t('email'), alignRight: false},
    { id: 'phone', label: t('phone'), alignRight: false },
    { id: 'paid', label: t('payment'), alignRight: false }
  ];

  const refreshTable = async () => {
    setIsLoading(true)
    await fetchData(`retreats/${retreat.hashid}/participants`, filter, sort, setParticipants, sortField, setPagination, page, setNotFound)
    setIsLoading(false)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(refreshTable, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [filter, sort, page, sortField])


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = participants.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangeInput = (e, employeeId) => {
    const {  checked } = e.target
    setEditingStatus(checked)
    
  }

  const handleEdit = async () => {
    setSubmiting(true);
    const data = {
      participant_id: editingParticipant.hashid,
      paid: editingStatus
    }
    await doSave(`retreats/${retreat.hashid}/participants`, data, t('price_save_success'), false, false, true); 
    setSubmiting(false);
    setEditingStatus(null)
    setEditingParticipant(null)
    refreshTable();
  }

  const handleFilterByName = (event) => {
    setFilter(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - participants.length) : 0;

  const renderPaidLabel = (paid) => {
    return paid ? <span style={{color: 'green'}}>Pagado</span>: <span style={{color: 'red'}}>Sin Pagar</span>
  }

  return (
    
        <>
          <ListToolbar numSelected={selected.length} filterName={filter} onFilterName={handleFilterByName} placeholder={`search_participants`} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {isLoading?  <FadeLoader color={'blue'} loading={loading} cssOverride={override} size={50} /> :
                <>
                <ListHead
                  headLabel={TABLE_HEAD}
                  omitCheckbox
                  rowCount={participants.length}
                  order={sort}
                  orderBy={sortField}
                  numSelected={selected.length}
                  onRequestSort={setSortField}
                  setSort={setSort}
                  onSelectAllClick={handleSelectAllClick}

                />
                <TableBody>
                   {participants.map((row) => {
                      const { name, phone, email, hashid, paid} = row;
                      const isItemSelected = selected.indexOf(hashid) !== -1;
                      const isEditing = editingParticipant && editingParticipant.hashid === hashid;
                      return (
                        <TableRow
                          hover
                          key={hashid}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{phone}</TableCell>
                          <TableCell align="left">
                            {isEditing 
                            ?   <FormControlLabel  onChange={onChangeInput} control={<Checkbox defaultChecked={paid} />} label={t('paid')} />
                              : renderPaidLabel(paid)}
                            {!isEditing && 
                              <Iconify onClick={() => {setEditingParticipant(row)}} style={{cursor: 'pointer', marginLeft: '10px'}} icon="mdi:lead-pencil" width={15} height={15} />}
                            {isEditing &&  
                              <LoadingButton style={{marginLeft: 15, marginTop: 10}} size="small" onClick={handleEdit} variant="contained" loading={submiting}>
                                 {t('save')}
                            </LoadingButton>}
                          </TableCell>
                        </TableRow>
                      );
                    }) }
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {notFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filter} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                </>}
                  </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={pagination.total}
            rowsPerPage={pagination.per_page}
            page={page -1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
  );
}
