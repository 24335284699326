import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {ClipLoader, FadeLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import tracker from "../api/tracker";
import UserForm from "../sections/@dashboard/user/UserForm";
import PostForm from "../sections/@dashboard/blog/PostForm";
import EventForm from "../sections/@dashboard/events/EventForm";
import Announcements from "./Announcements";
import AnnouncementForm from "../sections/@dashboard/announcements/AnnouncementForm";



// ---------------------------------------------------------------------


const override = {
  // display: "block",
  margin: "0 auto",
  // borderColor: "red",
};


export default function CreateAnnouncement() {

  const [loading, setLoading] = useState(true)

  const { t } = useTranslation();



  const fetchUserData = async (filter, page) =>{

  }

  useEffect(() => {




  }, [])



  return (
    <Page title={t('Create_New_Announcement')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Create_New_Announcements')}
          </Typography>
        </Stack>
        <Card style={{padding: "50px"}}>
         <AnnouncementForm />
        </Card>
      </Container>
    </Page>
  );
}
