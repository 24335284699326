// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Usuarios',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Carrusel',
    path: '/dashboard/announcements',
    icon: getIcon('eva:home-fill'),
  },
  {
    title: 'Eventos',
    path: '/dashboard/events',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Artículos',
    path: '/dashboard/blog',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'Recursos',
    path: '/dashboard/resources',
    icon: getIcon('akar-icons:shipping-box-02'),
  },
  {
    title: 'Retiros',
    path: '/dashboard/retreats',
    icon: getIcon('akar-icons:shipping-box-02'),
  },
];

export default navConfig;
