import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';

// components
import ScrollToTop from './components/ScrollToTop';
import tracker from "./api/tracker";




// ----------------------------------------------------------------------

export default function App() {


    useEffect(() => {

       // getUserData()
    }, [])
  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
    </ThemeProvider>
  );
}
