import * as Yup from 'yup';
import {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {Stack, IconButton, InputAdornment} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css'
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import RHFSelect from "../../../components/hook-form/RHFSelect";
import tracker from "../../../api/tracker";
import RHFFileInput from "../../../components/hook-form/RHFFileInput";
import Label from "../../../components/Label";



// ----------------------------------------------------------------------

export default function PostForm({post}) {
  const navigate = useNavigate();
  const {t} = useTranslation()
  const [body, setBody] = useState(post ? post.body: '');

  useEffect(() => {

    if(post){
      const fields = ['title', 'summary', 'body', 'source']
      fields.forEach(field => setValue(field, post[field]));
    }
  }, [post])

  const PostSchema = Yup.object().shape({
    title: Yup.string().required(t('Required')),
    // password: Yup.string().required(t('Required')),
    summary: Yup.string().required('Required'),
    // body: Yup.string().required(t('Required')),
    // image: Yup.mixed().test("fileSize", "The file is too large", (value) => {
    //   console.log(value)
    //   return true
    // }),
    // thumbnail: Yup.mixed().test("fileSize", "The file is too large", (value) => {
    //   console.log(value)
    //   return true
    // }),
  });

  const defaultValues = {
    title:'',
    summary:'',
    body:'',
    image:null,
    thumbnail:null
  };
  // if(!user)
  //   user = defaultValues

  const methods = useForm({
    resolver: yupResolver(PostSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    if(data.image){
      data.image = data.image[0];
    }
    if(data.thumbnail){
      data.thumbnail = data.thumbnail[0];
    }

    data.body = body;

    console.log(data);
    if(!post)
      await tracker.post('articles', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    else
      await tracker.post(`articles/update/${post.hashid}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
     navigate('/dashboard/blog', { replace: true });
  };

  return (
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack direction="column" spacing={2}>
            <RHFTextField name="title" label={t('Title')} />
            <RHFTextField name="summary" label={t('Summary')} />
            <ReactQuill style={{height:'300px', marginBottom: "50px"}} theme="snow" value={body} onChange={setBody} />
            <RHFTextField name="source" label={t('source')} />
            <Label>{t('Imagen')}</Label>
            <RHFFileInput  name="image" fileUrl={post? post.image : null} />
            <Label>{t('Thumbnail')}</Label>
            <RHFFileInput  name="thumbnail" fileUrl={post? post.thumbnail : null} />

          </Stack>
         

      
        

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            {t('Save')}
          </LoadingButton>
        </Stack>
      </FormProvider>
  );
}
