import PropTypes from 'prop-types';
// form
import {useFormContext, Controller} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Stack } from '@mui/material';
import {useState, useEffect} from "react";
import Iconify from '../Iconify';


// @mui

// ----------------------------------------------------------------------

RHFFileInput.propTypes = {
  name: PropTypes.string,
};

export default function RHFFileInput({ name, fileUrl, ...other }) {
  const { control } = useFormContext();
  const {t} = useTranslation();
  const [value, setValue] = useState("");
  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(fileUrl);

  const changeHandler = (e) => {
    const file = e.target.files[0];
    setFile(file);
  }
  useEffect(() => {
    let fileReader = false;
    let isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result)
        }
      }
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }

  }, [file]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack direction="column" alignContent="center" spacing={1}>
           {fileDataURL &&
          <>
            <Avatar style={{height:"100px", width:"100px" , alignSelf: 'center'}} src={fileDataURL} alt="preview" />
          </>
          }
          <Button
            variant="contained"
            color="success"
            style={{height: "30px", alignSelf: "center"}}
            component="label"
            >
            <Iconify icon="mdi:cloud-upload" width={24} height={24} />
            {t('set_picture')}

            <input
              {...field}
              type="file"
              accept={"images"}
              value={value}
              onChange={(e) => {
                  setValue(e.target.value);
                  field.onChange(e.target.files);
                  changeHandler(e);
              }}
              {...other}
              hidden
              />     
            </Button>
         
        </Stack>
       
      )}
    />
  );
}
