import { createSlice } from '@reduxjs/toolkit'
import tracker from "../api/tracker";

const initialUser = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
// Slice
const slice = createSlice({
    name: 'user',
    initialState: {
        user: initialUser,
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.user = action.payload.user;
            localStorage.setItem('user', JSON.stringify(action.payload.user))
            localStorage.setItem('token', action.payload.user.token)
        },
        logoutSuccess: (state, action) =>  {
            localStorage.setItem('user', null)
            state.user = null;
        },
    },
});
export default slice.reducer
// Actions
const { loginSuccess, logoutSuccess } = slice.actions
export const login = ({ email, password }) => async dispatch => {
    try {
        const res = await tracker.post('login', { email, password })
        dispatch(loginSuccess(res.data));
    } catch (e) {
        return console.error(e.message);
    }
}
export const logout = () => async dispatch => {
    try {
        await tracker.post('logout')
        return dispatch(logoutSuccess())
    } catch (e) {
        return console.error(e.message);
    }
}