import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {ClipLoader, FadeLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import {Link as RouterLink, useParams} from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components

// mock


import tracker from "../../../api/tracker";
import Page from "../../../components/Page";
import AnnouncementForm from "./AnnouncementForm";



// ---------------------------------------------------------------------


const override = {
  // display: "block",
  margin: "0 auto",
  // borderColor: "red",
};


export default function EditAnnouncement() {

  const [loading, setLoading] = useState(true)
  const { hashid } = useParams()
  const [announcement, setEvent] = useState()
  const { t } = useTranslation();
  const fetchAnnouncementData = async () =>{
      const response = await tracker.get(`announcements/${hashid}`)
    setEvent(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchAnnouncementData()
  }, [hashid])

  return (
    <Page title={t('Announcemen_form')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Announcement_form')}
          </Typography>
        </Stack>
        <Card style={{padding: "50px"}}>
          {loading ? <FadeLoader color="blue" cssOverride={{margin: "0 auto"}} loading={loading} />
              :  <AnnouncementForm announcement={announcement} />}

        </Card>
      </Container>
    </Page>
  );
}
