import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import {login, logout} from '../../../slices/userSlice'
import tracker from "../../../api/tracker";



// ----------------------------------------------------------------------

export default function LoginForm() {

    const { t } = useTranslation();
  const navigate = useNavigate();
    const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t("Enter_a_valid_email")).required(t("Email_is_required")),
    password: Yup.string().required(t("Password_is_required")),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: false,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
      setIsLoading(true)
      await dispatch(login(data))
      navigate('/dashboard', { replace: true });
      setIsLoading(false)
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label={t("Correo_Electronico")}/>

        <RHFTextField
          name="password"
          label="Contrasena"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
         <LoadingButton ful lWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Iniciar Sesion
        </LoadingButton>
      </Stack>


     
    </FormProvider>
  );
}
