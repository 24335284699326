import tracker from "../api/tracker";


export default function useQuery() {
    const fetchData = async (model,search, sort, setData, sortField = 'created_at', setPagination = false, page = 1 ) => {
        let data
        if (search === ""){
            data = await tracker.get(`${model}?sort=${sortField}:${sort}&page=${page}`);

            setData(data.data.data);

        }else {
            data = await tracker.get(`${model}?search=${search}&sort=${sortField}:${sort} &page=${page}`);
            setData(data.data.data);
        }
        if(setPagination)
            setPagination(data.data.meta)
    }
    return fetchData
}