import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';

// material
import { Card, Stack, Button, Container, Typography } from '@mui/material';

// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

// mock
import tracker from '../api/tracker';
import { ResourceForm } from '../sections/@dashboard/resources';

// ---------------------------------------------------------------------

export default function SaveResource() {
  const { resourceid } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [resourceData, setResourceData] = useState(null);
  const [isLoading, setIsLoading] = useState(!!resourceid);

  const fetchResourceData = async () => {
    setIsLoading(true);

    const response = await tracker.get(`resources/${resourceid}`);
    setResourceData(response.data.data);

    setIsLoading(false);
  };
  useEffect(() => {
    if (resourceid) {
      fetchResourceData();
    }
  }, []);

  const title = `${t('Resource_form')}`;

  return (
    <Page title={title}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {`${title}: ${resourceid ? t('Update_resource') : t('New_resource')}`}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Button
            variant="outlined"
            component={RouterLink}
            sx={{
              color: 'black',
              border: '1px solid grey',
              '&:hover': {
                backgroundColor: 'grey',
                border: '1px solid black',
                color: 'black',
              },
              containedInherit: {
                color: 'green',
              },
            }}
            to="/dashboard/resources/"
            startIcon={<Iconify icon="eva:arrow-back-outline" />}
          >
            {t('Go_back')}
          </Button>
        </Stack>

        <Card style={{ padding: '50px' }}>
          {isLoading ? (
            <FadeLoader
              color={'blue'}
              loading={isLoading}
              cssOverride={{
                margin: '0 auto',
              }}
              size={50}
            />
          ) : (
            <>
              <ResourceForm resourceData={resourceData} />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
