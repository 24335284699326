import { Link as RouterLink } from 'react-router-dom';
// material
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import {FadeLoader} from "react-spinners";
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { BlogPostCard } from '../sections/@dashboard/blog';

import useQuery from "../hooks/useQuery";
import SortDropdown from "../components/SortDropdown";
import SearchModel from "../components/SearchModel";




export default function Blog() {
  const { t } = useTranslation();

  const [posts ,setPosts] = useState([]);
  const [filterBlog ,setFilterBlog] = useState("");
  const [sort ,setSort] = useState('desc');
  const [isLoading, setIsLoading] = useState(true);
  const fetchBlogData = useQuery();

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setIsLoading(true)
      await fetchBlogData('articles', filterBlog, sort, setPosts)
      setIsLoading(false)
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [filterBlog, sort])

  return (
    <Page title="Dashboard: Blog">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Blog')}
          </Typography>
          <Button variant="contained" component={RouterLink} to="/dashboard/blog/create" startIcon={<Iconify icon="eva:plus-fill" />}>
            {t('Create_Post')}
          </Button>
        </Stack>

        <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <SearchModel placeHolder={t('Search_blogs')} onFilterName={setFilterBlog} filterName={filterBlog} />
          <SortDropdown sort={sort} setSort={setSort} />
        </Stack>
        <Grid container spacing={3}>
          {isLoading ? (
              <FadeLoader
                  color={'blue'}
                  loading={isLoading}
                  cssOverride={{
                    margin: '0 auto',
                  }}
                  size={50}
              />
          ) : (
              posts.map((post, index) => (
            <BlogPostCard key={post.id} post={post} index={index} />
          )))}
        </Grid>
      </Container>
    </Page>
  );
}
