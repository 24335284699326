import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {ClipLoader, FadeLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';

import {Link as RouterLink, useParams} from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';


import ImageUploading from 'react-images-uploading';
// components

// mock

import {LoadingButton} from "@mui/lab";
import placeholder from './placeholder-video.png'; // with import
import tracker from "../../../api/tracker";
import Page from "../../../components/Page";
import Iconify from "../../../components/Iconify";




// ---------------------------------------------------------------------


const override = {
  // display: "block",
  margin: "0 auto",
  // borderColor: "red",
};


export default function EventPhotos() {

  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const { hashid } = useParams()
  const [photos, setPhotos] = useState([])
  const { t } = useTranslation();

  const fetchEventData = async () =>{
      const response = await tracker.get(`events/${hashid}`)
    setPhotos(response.data.data.photos)
    setLoading(false)
  }

  const onSubmit = async () => {
    const files = photos.filter((photo) => photo.file )
    const filesFormatted = files.map((photo) => photo.file )
    console.log(files)
    const data = {"photos[]": filesFormatted}
    setSaving(true)
    await tracker.post(`events/photos/${hashid}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    setSaving(false)
  }

  const onChange = (imageList, addUpdateIndex) => {
    console.log('heh');
    // data for submit
    setPhotos(imageList);
  };


  useEffect(() => {
    fetchEventData()
  }, [hashid])

  async function onDelete(image) {
    setLoading(true)
    await tracker.delete(`assets/${image.hashid}`)
    setLoading(false)
  }

  return (
        <Card style={{padding: "50px"}}>
          {loading ? <FadeLoader color="blue" cssOverride={{margin: "0 auto"}} loading={loading} />
              :
              <>
                <ImageUploading
                    multiple
                    value={photos}
                    onChange={onChange}
                    allowNonImageType
                    dataURLKey="url"
                >
                  {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <Button
                            variant="contained"
                            style={{marginBottom: "50px"}}
                            onClick={onImageUpload}

                            {...dragProps}
                            className=""
                        >
                          {t('Click_or_Drag_files_here')}
                        </Button>
                        &nbsp;
                        <Button style={{marginBottom: "50px"}} onClick={onImageRemoveAll}  color="error"  startIcon={<Iconify icon="quill:folder-trash" />}>{t('remove_all')}</Button>
                        <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
                          {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <img src={image.type === 'image' ? image.url: placeholder} alt="" width="100" />
                                <div className="image-item__btn-wrapper">
                                  <Button
                                      startIcon={<Iconify icon="quill:folder-trash" />}
                                      color="error"
                                      onClick={() => {onImageRemove(index); onDelete(image)}}>{t('remove')}</Button>
                                </div>
                              </div>
                          ))}
                        </div>
                        
                      </div>

                  )}
                </ImageUploading>
                <LoadingButton
                    fullWidth size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                    loading={saving}>
                  {t('Save')}
                </LoadingButton>
              </>
          }

        </Card>
  );
}
