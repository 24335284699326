import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {ClipLoader, FadeLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import {Link as RouterLink, useParams} from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';

import ImageUploading from 'react-images-uploading';
// components

// mock

import {LoadingButton} from "@mui/lab";
import tracker from "../../../api/tracker";
import Page from "../../../components/Page";
import Iconify from "../../../components/Iconify";




// ---------------------------------------------------------------------


const override = {
  // display: "block",
  margin: "0 auto",
  // borderColor: "red",
};


export default function EventVideos() {

  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const { hashid } = useParams()
  const [photos, setPhotos] = useState([])
  const { t } = useTranslation();

  const fetchEventData = async () =>{
      const response = await tracker.get(`events/${hashid}`)
    setPhotos(response.data.data.photos)
    setLoading(false)
  }

  const onSubmit = async () => {
    const files = photos.filter((photo) => photo.file )
    const filesFormatted = files.map((photo) => photo.file )
    console.log(files)
    const data = {"photos[]": filesFormatted}
    setSaving(true)
    await tracker.post(`events/photos/${hashid}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    setSaving(false)
  }

  const onChange = (imageList, addUpdateIndex) => {
    console.log('heh');
    // data for submit
    setPhotos(imageList);
  };


  useEffect(() => {
    fetchEventData()
  }, [hashid])

  async function onDelete(image) {
    setLoading(true)
    await tracker.delete(`assets/${image.hashid}`)
    setLoading(false)
  }

  return (
    <Page title={t('Event_form')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Event_videos')}
          </Typography>
        </Stack>
        <Card style={{padding: "50px"}}>
          {loading ? <FadeLoader color="blue" cssOverride={{margin: "0 auto"}} loading={loading} />
              :
              <>

                <LoadingButton
                    fullWidth size="large"
                    type="submit"
                    variant="contained"
                    onClick={onSubmit}
                    loading={saving}>
                  {t('Save')}
                </LoadingButton>
              </>
          }

        </Card>
      </Container>
    </Page>
  );
}
