import * as Yup from 'yup';
import {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {Stack, IconButton, InputAdornment} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css'
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import RHFSelect from "../../../components/hook-form/RHFSelect";
import tracker from "../../../api/tracker";
import RHFFileInput from "../../../components/hook-form/RHFFileInput";



// ----------------------------------------------------------------------

export default function UserForm({user}) {
  const navigate = useNavigate();
  const {t} = useTranslation()
  const [showPassword, setShowPassword] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const fetchRoleData = async () =>{
    const result = await tracker.get('roles')
    setRoleList(result.data.data)
  }

  useEffect(() => {
    if(user){
      const fields = ['email', 'name', 'hashid', 'role']
      fields.forEach(field => setValue(field, user[field]));
    }

    fetchRoleData()
  }, [user])

  const UserSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')),
    // password: Yup.string().required(t('Required')),
    email: Yup.string().email(t('Valid_email')).required('Required'),
    role: Yup.string().required(t('Required')),
    picture: Yup.mixed().test("fileSize", "The file is too large", (value) => {
      console.log(value)
    return true
    }),
  });

  const defaultValues = {
    name: '',
    password: '',
    email: '',
    role: 'Writer',
    picture: null
  };
  // if(!user)
  //   user = defaultValues

  const methods = useForm({
    resolver: yupResolver(UserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
      setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
   data.picture = data.picture[0];
    if(!user)
      await tracker.post('register', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    else
      await tracker.post(`users/update/${user.hashid}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    // navigate('/dashboard/user', { replace: true });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="name" label="First name" />
        </Stack>
        <RHFSelect name="role" label={t('Role')} options={roleList} />
        <RHFTextField name="email" label="Email address" />
        <input type="hidden" name="hashid" />
        {!user ? <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
              ),
            }}
        /> : false }
        <RHFFileInput name="picture" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('Save')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
