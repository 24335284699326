import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Menu, MenuItem, Select } from '@mui/material';
import Icofont from 'react-icofont';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  hasIcons: PropTypes.bool,
};

export default function RHFSelect({ name, hasIcons, options, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Select
          {...field}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error?.message}
          {...other}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {hasIcons && <Icofont icon={option.value} size={2} sx={{ ml: 20 }} />}&nbsp;&nbsp;&nbsp;&nbsp;
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
}
