import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {ClipLoader, FadeLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import {Link as RouterLink, useParams} from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Box,
  Tab,
} from '@mui/material';
import { TabContext, TabPanel, TabList } from '@mui/lab';
// components

// mock

import tracker from "../../../api/tracker";
import Page from "../../../components/Page";
import RetreatForm from './RetreatForm';
import RetreatParticipants from './RetreatParticipants';




// ---------------------------------------------------------------------


const override = {
  // display: "block",
  margin: "0 auto",
  // borderColor: "red",
};


export default function EditRetreat() {

  const [loading, setLoading] = useState(true)
  const { hashid } = useParams()
  const [retreat, setRetreat] = useState()
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState('1');
  const fetchEventData = async () =>{
      const response = await tracker.get(`retreats/${hashid}`)
      setRetreat(response.data.data)
    setLoading(false)
  }

  const handleChange = (event, value) => {
    setTabIndex(value)
  }

  useEffect(() => {
    fetchEventData()
  }, [hashid])

  return (
    <Page title={t('retreat_form')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('retreat_form')}
          </Typography>
        </Stack>

        <Card style={{padding: "20px"}}>
        <TabContext value={tabIndex}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t('retreat_info')} value="1" />
              <Tab label={t('retreat_participants')} value="2" />
            </TabList>
          </Box>
          <TabPanel value="1"> {loading ? <FadeLoader color="blue" cssOverride={{margin: "0 auto"}} loading={loading} />
              :  <RetreatForm retreat={retreat} />}</TabPanel>
          <TabPanel value="2">
            <RetreatParticipants retreat={retreat} />
          </TabPanel>
        </TabContext>
        </Card>
      </Container>
    </Page>
  );
}
