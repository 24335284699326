import { Link as RouterLink } from 'react-router-dom';
// material
import {Pagination} from "@mui/lab";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import {FadeLoader} from "react-spinners";
// components
import Page from '../components/Page';

import Iconify from '../components/Iconify';

import SortDropdown from "../components/SortDropdown";
import useQuery from "../hooks/useQuery";
import SearchModel from "../components/SearchModel";
import AnnouncementCard from "../sections/@dashboard/announcements/AnnouncementCard";




export default function Announcements() {
  const { t } = useTranslation();

  const [announcements ,setAnnouncements] = useState([]);
  const [pagination, setPagination] = useState({total: 0, per_page: 0, current_page: 1, last_page: 1});
  const [page, setPage] = useState(1);
  const [filterAnnouncement ,setfilterAnnouncement] = useState("");
  const [sort ,setSort] = useState('desc');
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = useQuery();

  const handleChange = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setIsLoading(true)
      await fetchData('announcements', filterAnnouncement, sort, setAnnouncements, 'created_at', setPagination, page)
      setIsLoading(false)
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [filterAnnouncement, sort, page])

  return (
      <Page title="Dashboard: Announcement">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {t('Announcements')}
            </Typography>
            <Button variant="contained" component={RouterLink} to="/dashboard/Announcement/create" startIcon={<Iconify icon="eva:plus-fill" />}>
              {t('Create_Announcement')}
            </Button>
          </Stack>

          <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
            <SearchModel filterName={filterAnnouncement} onFilterName={setfilterAnnouncement} placeHolder={t("Search_Announcement")} />
            <SortDropdown sort={sort} setSort={setSort} />
          </Stack>

          <Grid container spacing={3}>
            {isLoading ? (<FadeLoader
                color={'blue'}
                loading={isLoading}
                cssOverride={{
                  margin: '0 auto',
                }}
                size={50}
            />) : (
                announcements.map((announcement, index) => (
                  <AnnouncementCard key={announcement.id} announcement={announcement} index={index} />
              )
                )) }

          </Grid>
          <div style={{display: "flex", alignItems: 'center', justifyContent: 'center', marginTop: '20px'}}>
            <Pagination count={pagination.last_page} page={page}  variant="outlined" shape="rounded" onChange={handleChange} />
          </div>

        </Container>
      </Page>
  );
}
