import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {ClipLoader, FadeLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import {Link as RouterLink, useParams} from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import tracker from "../api/tracker";
import UserForm from "../sections/@dashboard/user/UserForm";



// ---------------------------------------------------------------------


const override = {
  // display: "block",
  margin: "0 auto",
  // borderColor: "red",
};


export default function EditUser() {

  const [loading, setLoading] = useState(true)
  const { hashid } = useParams()
  const [user, setUser] = useState()
  const { t } = useTranslation();
  const fetchUserData = async () =>{
      const response = await tracker.get(`users/${hashid}`)
    setUser(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchUserData()
  }, [hashid])

  return (
    <Page title={t('User_form')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('User_form')}
          </Typography>
        </Stack>
        <Card style={{padding: "50px"}}>
          {loading ? <FadeLoader color="blue" cssOverride={{margin: "0 auto"}} loading={loading} />
              :  <UserForm user={user} />}

        </Card>
      </Container>
    </Page>
  );
}
