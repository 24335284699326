import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Events';
import DashboardApp from './pages/DashboardApp';
import PrivateRoutes from "./utils/PrivateRoute";
import SaveUser from "./pages/SaveUser";
import EditUser from "./pages/EditUser";
import CreatePost from "./pages/CreatePost";
import EditPost from "./pages/EditPost";
import CreateEvent from "./pages/CreateEvent";
import EditEvent from "./sections/@dashboard/events/EditEvent";
import ResourcesPage from './pages/Resources';
import SaveResource from "./pages/SaveResource";
import Announcements from "./pages/Announcements";
import EditAnnouncement from "./sections/@dashboard/announcements/EditAnnouncement";
import CreateAnnouncement from "./pages/CreateAnnouncement";
import EventPhotos from "./sections/@dashboard/events/EventPhotos";
import EventVideos from "./sections/@dashboard/events/EventVideos";
import EditRetreat from './sections/@dashboard/retreats/EditRetreat';
import CreateRetreat from './pages/CreateRetreat';
import Retreats from './pages/Retreats';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      element: <PrivateRoutes />,
      children: [
        {
          path: '/dashboard',
          element: <DashboardLayout />,
          children: [
            { path: 'app', element: <DashboardApp /> },

            {path: 'user', element: <User />},
            {path: 'user/save', element: <SaveUser />},
            {path: 'user/edit/:hashid', element: <EditUser />},
            {path: 'blog/edit/:hashid', element: <EditPost />},
            {path: 'events', element: <Products /> },
            {path: 'event/create', element: <CreateEvent />},
            {path: 'event/edit/:hashid', element: <EditEvent />},
            {path: 'event/photos/:hashid', element: <EventPhotos />},
            {path: 'event/videos/:hashid', element: <EventVideos />},
            {path: 'retreats', element: <Retreats /> },

            {path: 'retreat/create', element: <CreateRetreat />},
            {path: 'retreat/edit/:hashid', element: <EditRetreat />},

            {path: 'announcements', element: <Announcements /> },
            {path: 'announcement/create', element: <CreateAnnouncement />},
            {path: 'announcement/edit/:hashid', element: <EditAnnouncement />},

            { path: 'blog', element: <Blog /> },
            { path: 'blog/create', element: <CreatePost /> },
            { path: 'resources', element: <ResourcesPage /> },
            { path: 'resources/new', element: <SaveResource /> },
            { path: 'resources/edit/:resourceid', element: <SaveResource /> },
          ],
        },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/user" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
