import * as Yup from 'yup';
import {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from "moment";
// @mui
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {Stack, IconButton, InputAdornment, TextField} from '@mui/material';
import {DesktopDatePicker, LoadingButton, LocalizationProvider} from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css'
// components
import Iconify from '../../../components/Iconify';
import {FormProvider, RHFCheckbox, RHFTextField} from '../../../components/hook-form';
import RHFSelect from "../../../components/hook-form/RHFSelect";
import tracker from "../../../api/tracker";
import RHFFileInput from "../../../components/hook-form/RHFFileInput";
import Label from "../../../components/Label";




// ----------------------------------------------------------------------

export default function AnnouncementForm({announcement}) {
  const navigate = useNavigate();
  const {t} = useTranslation()


  useEffect(() => {

    if(announcement){
      const fields = ['body', 'image', 'title','url', 'active']
      fields.forEach(field => setValue(field, announcement[field]));
    }
  }, [announcement])

  const AnnouncementSchema = Yup.object().shape({
   // title: Yup.string().required(t('Required')),
    // password: Yup.string().required(t('Required')),
   // body: Yup.string().required('Required'),
    // body: Yup.string().required(t('Required')),
    // image: Yup.mixed().test("fileSize", "The file is too large", (value) => {
    //   console.log(value)
    //   return true
    // }),
    // thumbnail: Yup.mixed().test("fileSize", "The file is too large", (value) => {
    //   console.log(value)
    //   return true
    // }),
  });

  const defaultValues = {
    active:1,
    body: '',
    title: '',
    url:'',
    image:null,
  };
  // if(!user)
  //   user = defaultValues

  const methods = useForm({
    resolver: yupResolver(AnnouncementSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    if(data.image){
      data.image = data.image[0];
    }
    console.log(data);
    if(!announcement)
      await tracker.post('announcements', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    else
      await tracker.post(`announcements/update/${announcement.hashid}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
     navigate('/dashboard/announcements', { replace: true });
  };

  return (
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFTextField name="title" label={t('Title')} />
          </Stack>
          <RHFTextField name="body" label={t('Body')} />

          <div style={{marginTop: "80px"}}>
            <Label>{t('Imagen')}</Label>
            <RHFFileInput fileUrl={announcement ? announcement.image : null}  name="image" />
          </div>
          <RHFTextField name="url" label={t('link')} />
          <RHFCheckbox name="active" label="Activo"/>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            {t('Save')}
          </LoadingButton>
        </Stack>
      </FormProvider>
  );
}
