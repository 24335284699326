import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import {Pagination} from "@mui/lab";

// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';

// react-i18n
import { useTranslation } from 'react-i18next';

import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { ResourceCard, ResourcesSort, ResourcesSearch } from '../sections/@dashboard/resources';
import SearchNotFound from '../components/SearchNotFound';
import tracker from '../api/tracker';

// ----------------------------------------------------------------------
import RESOURCES from '../_mock/resources';
import SearchModel from "../components/SearchModel";
import SortDropdown from "../components/SortDropdown";
import useQuery from "../hooks/useQuery";


const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'oldest', label: 'Oldest' },
];

export default function ResourcesPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [resourcesList, setResourcesList] = useState([]);
  const [noResourcesFound, setNoResourcesFound] = useState(false);
  const [pagination, setPagination] = useState({total: 0, per_page: 0, current_page: 1, last_page: 1});
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("")
  const [sort, setSort] = useState('desc')

  const fetchData = useQuery();
  const handleChange = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      setIsLoading(true)
      await fetchData('resources', search, sort, setResourcesList, 'created_at', setPagination, page)
      setIsLoading(false)
    }, 500 * 1);

    return () => {
      clearTimeout(timer);
    };
  }, [sort, search, page]);

  return (
    <Page title={`Dashboard: ${t('Resources')}`}>
      <Container>
        {/* TODO: Make sure this is responsive */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Resources_header_title')}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/resources/new"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {t('New_resource')}
          </Button>
        </Stack>

        <>
          <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
            <SearchModel filterName={search} onFilterName={setSearch} placeHolder={t("Search_resources")} />
            <SortDropdown sort={sort} setSort={setSort} />
          </Stack>

          <Grid container spacing={3} justifyContent="start">
            {isLoading ? (
              <FadeLoader
                color={'blue'}
                loading={isLoading}
                cssOverride={{
                  margin: '0 auto',
                }}
                size={50}
              />
            ) : (
              resourcesList.map((resource, index) => (
                <ResourceCard key={resource.hashid} resource={resource} index={index} />
              ))
            )}
            {noResourcesFound && <SearchNotFound />}
          </Grid>
          <div style={{display: "flex", alignItems: 'center', justifyContent: 'center', marginTop: '20px'}}>
            <Pagination count={pagination.last_page} page={page}  variant="outlined" shape="rounded" onChange={handleChange} />
          </div>
        </>
      </Container>
    </Page>
  );
}
