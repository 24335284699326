import PropTypes from 'prop-types';

// @mui
import { styled } from '@mui/material/styles';
import {Autocomplete, InputAdornment, OutlinedInput, Popper, TextField} from '@mui/material';
import {useTranslation} from "react-i18next";
// components
import Iconify from './Iconify';


// ----------------------------------------------------------------------

const PopperStyle = styled((props) => <Popper placement="bottom-start" {...props} />)({
    width: '280px !important',
});

// ----------------------------------------------------------------------

SearchModel.propTypes = {
    filterName: PropTypes.string.isRequired,
    onFilterName: PropTypes.func.isRequired,
    placeHolder: PropTypes.string.isRequired,
};

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

export default function SearchModel({ filterName, onFilterName, placeHolder }) {

    const handleSearch = (event) =>{
        onFilterName(event.target.value)
    }
    return (
        <SearchStyle
            value={filterName}
            onChange={handleSearch}
            placeholder={placeHolder}
            startAdornment={
                <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
            }
        />
    );
}
